/* *************** HEADER COMPONENT + SEARCH BOX COMPONENT + CATERGORY DROPDOWN **************** */

.buyerHeaderDiv {
    background: none;
}

.buyerHeaderDiv .buyerHeaderContent {
    padding: 30px 0px;
    display: flex;
    flex-direction: column;
    gap: 22px;
}

.buyerHeaderDiv .buyerHeaderContent .buyerHeaderUpper,
.buyerHeaderDiv .buyerHeaderContent .buyerHeaderUpper,
.buyerHeaderActions,
.buyerHeaderDiv .buyerHeaderContent .buyerHeaderUpper,
.buyerHeaderActions,
.leftActions,
.buyerHeaderDiv .buyerHeaderContent .buyerHeaderUpper,
.buyerHeaderActions,
.rightActions,

.buyerHeaderDiv .buyerHeaderContent .buyerHeaderLower,
.buyerHeaderDiv .buyerHeaderContent .buyerHeaderLower .lowerLeft ul,
.buyerHeaderDiv .buyerHeaderContent .buyerHeaderLower .lowerRight ul,

.searchBoxDiv .searchBoxMain,
.searchBoxDiv .searchBoxMain .searchBoxRight,
.searchBtnDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.buyerHeaderDiv .buyerHeaderContent .buyerHeaderUpper,
.buyerHeaderDiv .buyerHeaderContent .buyerHeaderLower {
    justify-content: space-between;
}

.buyerHeaderDiv .buyerHeaderContent .buyerHeaderUpper {
    gap: 55px;
}

.buyerHeaderDiv .buyerHeaderContent .buyerHeaderUpper .buyerHeaderActionsBefore {
    display: flex;
    align-items: center;
    gap: 20px;
    min-width: 220px;
}

.buyerHeaderDiv .buyerHeaderContent .buyerHeaderUpper .buyerHeaderActionsBefore.hide {
    visibility: hidden;
}

.buyerHeaderDiv .buyerHeaderContent .buyerHeaderUpper .buyerHeaderActionsBefore.remove {
    display: none;
}

.buyerHeaderDiv .buyerHeaderContent .buyerHeaderUpper .buyerHeaderActionsBefore .primaryBtn {
    font-size: 0.8rem;
}

.buyerHeaderDiv .buyerHeaderContent .buyerHeaderUpper .buyerHeaderUpperLeft {
    display: flex;
    gap: 55px;
    align-items: center;
    width: 100%;
}

.searchBoxDiv {
    position: relative;
    width: 95%;
}

.searchBoxDiv .searchBoxMain {
    border-radius: 28px;
    width: 100%;
    height: 50px;
    justify-content: space-between;
    gap: 0;
    border: 2px solid var(--gray);
}

.searchBoxDiv .searchBoxMain .searchBoxLeft {
    width: 100%;
    padding-left: 25px;
}

.searchBoxDiv .searchBoxMain .searchBoxRight {
    background: var(--primaryBlue);
    height: 100%;
    border-top-right-radius: 28px;
    border-bottom-right-radius: 28px;
    padding: 10px 20px;
}

.searchBoxDiv .searchBoxMain .searchBoxRight .dropdown {
    color: var(--white);
}

.searchBoxDiv .searchBoxMain .searchBoxRight .dropdown option {
    color: var(--black);
}

.searchBoxDiv .searchBoxMain .searchBoxLeft .searchInput {
    outline: none;
    border: none;
    width: 100%;
    height: 100%;
    padding: 10px 0px;
}

.buyerHeaderActions {
    gap: 40px;
}

.buyerHeaderActions i {
    font-size: 20px;
    cursor: pointer;
    color: var(--lightBlack);
    transition: 0.1s;
}

.buyerHeaderActions i:hover {
    color: var(--black);
}

.buyerHeaderActions .leftActions,
.buyerHeaderActions .rightActions {
    gap: 25px;
}

.buyerHeaderActions .leftActions i {
    color: var(--primaryCopper);
    transition: 0.1s;
}

.buyerHeaderActions .leftActions i:hover {
    color: var(--primaryCopperDark);
}

.buyerHeaderActions .leftActions .ordersHeader{
    position: relative;
}
.buyerHeaderActions .leftActions .ordersHeader p {
    font-size: 20px;
    cursor: pointer;
    color: var(--lightBlack);
}

.buyerHeaderActions .leftActions .ordersHeader p:hover {
    color: var(--black);
}
.buyerHeaderActions .leftActions .inboxDiv {
    position: relative;
}


.buyerHeaderActions .rightActions {
    border: 1px solid var(--primaryCopper);
    border-radius: 8px;
    padding: 12px 25px;
    background-color: transparent;
}

.buyerHeaderDiv .buyerHeaderContent .buyerHeaderLower .lowerLeft ul,
.buyerHeaderDiv .buyerHeaderContent .buyerHeaderLower .lowerRight ul {
    gap: 30px;
}

.buyerHeaderDiv .buyerHeaderContent .buyerHeaderLower i {
    margin: 0px 10px;
}

.buyerHeaderDiv .buyerHeaderContent .buyerHeaderLower li a {
    display: flex;
    align-items: center;
    gap: 2px;
}

.buyerHeaderDiv .buyerHeaderContent .buyerHeaderLower .icon {
    width: 25px;
    height: 25px;
}

.buyerHeaderDiv .buyerHeaderContent .buyerHeaderLower .catsDropdownLi {
    position: relative;
}

.buyerHeaderDiv .buyerHeaderContent .buyerHeaderLower .catsDropdownLi .catsDropdownDiv {
    position: absolute;
    background: var(--white);
    box-shadow: 1px 1px 10px 1px var(--gray);
    top: 170%;
    left: 0;
    padding: 15px 20px;
    min-width: 700%;
    z-index: 1000;
}

.buyerHeaderDiv .buyerHeaderContent .buyerHeaderLower .catsDropdownLi .catsDropdownDiv .catsDropdownContent {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5px;
}

.buyerHeaderDiv .buyerHeaderContent .buyerHeaderLower .catsDropdownLi .catsDropdownDiv .cat {
    color: var(--lightBlack);
    cursor: pointer;
    transition: 0.1s;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 3px 10px;
    width: 280px;
}

.buyerHeaderDiv .buyerHeaderContent .buyerHeaderLower .catsDropdownLi .catsDropdownDiv .cat:hover {
    background: var(--lightGray);
}

.buyerHeaderDiv .buyerHeaderContent .buyerHeaderUpper .buyerHeaderDrawer {
    display: none;
}






/* *************** BUYER FOOOTER COMPONENT **************** */

.buyerFooterDiv {
    padding: 30px 0px;
    background: var(--primaryBlue);
}

.buyerFooterDiv .horizontalLine {
    height: 1px;
    background: var(--darkGray);
}

.buyerFooterDiv .footerContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
    color: var(--gray);
}

.buyerFooterDiv .footerContent a {
    color: var(--darkGray);
    transition: 0.2s;
}

.buyerFooterDiv .footerContent a:hover {
    color: var(--white);
}

.buyerFooterDiv .footerContent .footerUpper,
.buyerFooterDiv .footerContent .footerLower {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.buyerFooterDiv .footerContent .footerUpper {
    width: 95%;
    margin: 20px 0px;
    align-items: start;
    gap: 30px;
}

.buyerFooterDiv .footerContent .footerUpper .column {
    display: flex;
    flex-direction: column;
    gap: 6px;
    min-width: 17%;
}

.buyerFooterDiv .footerContent .footerUpper div h4 {
    margin-bottom: 10px;
    color: var(--white);
    font-weight: 600;
    font-size: 1.2rem;
}

.buyerFooterDiv .footerContent .footerUpper .footerUpperLeft {
    max-width: 25%;
    gap: 20px;
    margin-right: 50px;
}

.buyerFooterDiv .footerContent .footerUpper .footerUpperLeft .faithzyLogoDiv {
    width: 80px;
    height: 80px;
}

.buyerFooterDiv .footerContent .footerIconsDiv {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
}

.buyerFooterDiv .footerContent .socialIcons .icon {
    cursor: pointer;
}










/* *************** HERO SECTION **************** */

.heroDiv {
    position: relative;
    color: var(--primaryBlue);
    height: 84vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.heroDiv .heroContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 30px;
    height: 100%;
}

.heroDiv .heroLeft {
    display: flex;
    flex-direction: column;
    width: 60%;
    /* height: 90%; */
    gap: 30px;
    margin-left: 55px;
}

.heroDiv .heroLeft .heroContentMain {
    margin-left: 10px;
}

.heroDiv .heroContent .heroContentMain h1 {
    font-family: "Bebas Neue", sans-serif;
    font-size: 55px;
    letter-spacing: 2.5px;
    position: relative;
}

.heroDiv .heroContent .heroContentMain span {
    font-family: "Bebas Neue", sans-serif;
    color: var(--secondaryCopper);
    position: relative;
}

.heroDiv .heroContent .heroContentMain p {
    position: relative;
    font-size: 17px;
    color: var(--darkGray);
    margin: 10px 0px;
    line-height: 1.8;
    animation: fadeIn 2s ease-in-out;
    width: fit-content;
}

.heroDiv .heroContent .heroContentMain .heroBtns {
    display: flex;
    gap: 20px;
    margin-top: 20px;
    animation: fadeInUp 1s ease-in-out 0.5s;
    position: relative;
}

.heroDiv .heroContent .heroContentMain .heroBtns a {
    padding: 15px 25px;
}

.heroDiv .heroContent .heroContentMain .heroBtns .primaryBtn2 {
    border-color: var(--primaryCopper);
    color: var(--primaryCopper);
}

.heroDiv .heroContent .heroContentMain .heroBtns .primaryBtn2:hover {
    background: var(--primaryCopper);
    color: var(--white);
}

.heroDiv .heroTradeleadFormDiv {
    width: 100%;
    margin-left: 10px;
}

.heroDiv .heroTradeleadFormDiv .tradeleadStepper {
    padding: 20px 25px;
    border-radius: 12px;
    width: 100%;
    max-width: 660px;
    margin-top: 20px;
    background: var(--white);
    border: 2px solid var(--gray);
    position: relative;
}

.heroDiv .heroTradeleadFormDiv .tradeleadStepper .heroPageForm .tradeleadHeroLabel {
    color: var(--darkGray);
}

.heroDiv .heroTradeleadFormDiv .tradeleadStepper .heroPageForm .tradeleadHeroBtn {
    width: 100%;
    background: var(--orangeBgLight);
    border: 1px solid var(--primaryCopper);
    padding: 12px 20px;
}

.heroDiv .heroTradeleadFormDiv .tradeleadStepper .heroPageForm .tradeleadHeroBtn:hover {
    background: var(--orangeBg);
}

.heroDiv .heroTradeleadFormDiv .tradeleadStepper .stepper {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.heroDiv .heroTradeleadFormDiv .tradeleadStepper .step {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--gray);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.heroDiv .heroTradeleadFormDiv .tradeleadStepper .step.active,
.heroDiv .heroTradeleadFormDiv .tradeleadStepper .step.completed {
    background-color: var(--primaryCopper);
    color: var(--white);
}

.heroDiv .heroTradeleadFormDiv .tradeleadStepper .stepConnector {
    height: 2px;
    background-color: var(--darkGray);
    flex-grow: 1;
}

.heroDiv .heroTradeleadFormDiv .tradeleadStepper .stepConnector.active {
    background-color: var(--primaryCopper);
}


.heroDiv .heroTradeleadFormDiv .tradeleadStepper .stepContent .stepInputs {
    display: flex;
    flex-direction: column;
    gap: 12px;
    color: var(--black);
}

.heroDiv .heroTradeleadFormDiv .tradeleadStepper .stepContent .stepInputs .tradeleadInputDiv {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.heroDiv .heroTradeleadFormDiv .tradeleadStepper .stepContent .tradeleadDescription {
    resize: vertical;
    min-height: 100px;
    height: 138px;
    max-height: 200px;
}


.heroDiv .heroTradeleadFormDiv .tradeleadStepper .stepButtons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}

.heroDiv .heroTradeleadFormDiv .tradeleadStepper .mainStepBtn {
    margin-bottom: 6px;
}

.heroDiv .heroTradeleadFormDiv .tradeleadStepper .mainStepBtn button:hover {
    background: var(--primaryBlue);
    color: var(--white);
    border: none;
    border-color: transparent;
}

.heroDiv .heroTradeleadFormDiv .tradeleadStepper .showTradeleadModelDiv .popupContent {
    padding: 40px 50px;
}

.heroDiv .heroRight {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 100%;
}

.heroDiv .imageSlider {
    width: 90%;
    height: 90%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    justify-content: center;
}

.heroDiv .imageSlider .slideImage {
    width: 90%;
    height: 90%;
    object-fit: contain;
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

.heroDiv .imageSlider .fadeIn {
    opacity: 1;
    z-index: 1;
}

.heroDiv .imageSlider .fadeOut {
    opacity: 0;
    z-index: 0;
}


.heroDiv .imageSlider .currentImage {
    transform: translateX(0);
    z-index: 1;
}

.heroDiv .imageSlider .nextImage {
    opacity: 1;
    z-index: 0;
}

.heroDiv .imageSlider .dotsContainer {
    display: flex;
    gap: 7px;
    z-index: 100;
    display: none;
}

.heroDiv .imageSlider .dot {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: var(--primaryBlue);
    cursor: pointer;
    transition: background-color 0.3s;
}

.heroDiv .imageSlider .dot.active {
    background-color: #ccc;
}


















/* *************** REGISTER + LOGIN PAGE **************** */

.registerDiv .registerContent,
.registerDiv .registerContent .registerRight,
.thirdPartyLoginDiv,
.thirdPartyLoginDiv a,
.registerDiv .registerContent .registerRight .registerRightLower,

.resetPasswordRequestDiv .section,
.resetPasswordDiv .section,

.loginDiv .loginContent,
.loginDiv .loginContent .loginRight,
.loginDiv .loginContent .loginRight .loginRightLower {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.loginDiv,
.registerDiv,
.resetPasswordRequestDiv,
.resetPasswordDiv {
    position: relative;
    min-height: 99vh;
    display: flex;
    align-items: center;
}

.loginDiv .faithzyLogoDiv,
.registerDiv .faithzyLogoDiv,
.resetPasswordRequestDiv .faithzyLogoDiv,
.resetPasswordDiv .faithzyLogoDiv {
    position: absolute;
    top: 30px;
    left: 53px;
    z-index: 1000;
}

.loginDiv .loginBanner,
.resetPasswordRequestDiv .resetPasswordBanner,
.resetPasswordDiv .resetPasswordBanner {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
}

.registerDiv .registerBanner {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
}

.loginDiv .loginContent,
.resetPasswordRequestDiv .section,
.resetPasswordDiv .section {
    padding: 50px 0px;
    align-items: flex-end;
    margin-right: 100px;
}

.resetPasswordRequestDiv .section .form,
.resetPasswordDiv .section .form {
    max-width: 45%;
    width: 100%;
}

.registerDiv .registerContent {
    padding: 110px 0px;
    align-items: flex-start;
    margin-left: 70px;
}

.registerDiv .registerContent .registerRight,
.loginDiv .loginContent .loginRight {
    max-width: 45%;
    width: 100%;
    gap: 30px;
}

.loginDiv .emailVerificationNotification {
    background: var(--gray);
    padding: 15px 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10000;
}

.thirdPartyLoginDiv {
    width: 100%;
    gap: 12px;
}

.thirdPartyLoginDiv a {
    flex-direction: row;
    gap: 20px;
}

.thirdPartyLoginDiv .loginWithFacebook img,
.thirdPartyLoginDiv .loginWithGoogle img {
    width: 20px;
}

.thirdPartyLoginDiv a {
    color: var(--lightBlack);
    width: 100%;
    background: var(--white);
    padding: 8px 0px;
    border: 1px solid var(--black);
    border-radius: 32px;
    transition: 0.2s;
}

.thirdPartyLoginDiv a:hover {
    background: var(--lightGray);
}

.orLine {
    position: relative;
    color: var(--darkGray);
    width: 100%;
    text-align: center;
}

.orLine::before {
    position: absolute;
    content: "";
    top: 50%;
    left: 0%;
    width: 45%;
    height: 4%;
    background: var(--darkGray);
}

.orLine::after {
    position: absolute;
    content: "";
    top: 50%;
    right: 0%;
    width: 45%;
    height: 4%;
    background: var(--darkGray);
}


.registerDiv .registerContent .registerRight .registerRightLower,
.loginDiv .loginContent .loginRight .loginRightLower {
    width: 100%;
    gap: 10px;
}

.registerDiv .registerContent .registerRight .registerRightLower .inputDiv,
.loginDiv .loginContent .loginRight .loginRightLower .inputDiv {
    width: 100%;
}

.registerDiv .registerContent .registerRight .registerRightLower .inputDiv .passInstructions,
.loginDiv .loginContent .loginRight .loginRightLower .inputDiv .forgotPasswordBtn {
    color: var(--darkGray);
    font-size: 0.85rem;
}

.loginDiv .loginContent .loginRight .loginRightLower .inputDiv .forgotPasswordBtn:hover {
    color: var(--black);
}

.registerDiv .registerContent .registerRight .registerRightLower .inputDiv .primaryBtn,
.loginDiv .loginContent .loginRight .loginRightLower .inputDiv .primaryBtn {
    width: 100%;
    margin: 15px 0px;
}

.registerDiv .registerContent .registerRight .registerRightLower .loginInsteadDiv a,
.loginDiv .loginContent .loginRight .loginRightLower .registerInsteadDiv a {
    color: var(--primaryCopper);
}



/* *************** SAMPLE PROVISIONS COMPONENT **************** */

.provisionsFilterTabDiv {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    background: var(--lightGray);
    padding: 10px 25px;
    border-radius: 35px;
    border: 1px solid var(--darkGray);
}

.provisionsFilterTabDiv button {
    background: transparent;
    border: none;
    color: var(--darkGray);
    font-size: 0.8rem;
    cursor: pointer;
    transition: 0.2s;
}

.provisionsFilterTabDiv button:hover {
    color: var(--lightBlack);
}

.provisionsFilterTabDiv button.selected {
    color: var(--black);
}

.sampleProvisionsDiv {
    margin: 100px 0px;
}

/* .homeProvisionsDiv .homeProvisionsContainer {
    margin-bottom: 100px;
} */

.homeProvisionsDiv .homeProvisionsContainer .sampleProvisionsDiv {
    margin: 50px 0px;
}

.homeProvisionsDiv .homeProvisionsContainer .sampleProvisionsDiv .primaryHeading {
    font-size: 1.5rem;
    font-weight: 600;
}

.sampleProvisionsDiv .sampleProvisionsUpper,
.sampleProvisionsDiv .sampleProvisionsUpper .sampleProvisionsUpperRight {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sampleProvisionsDiv .sampleProvisionsUpper {
    margin-bottom: 5px;
}

.sampleProvisionsDiv .sampleProvisionsLower {
    position: relative;
}

.sampleProvisionsDiv .sampleProvisionsLower .slideLeftBtn {
    position: absolute;
    left: -1%;
    top: 50%;
    height: 45px;
    width: 45px;
    background: var(--primaryBlue);
    color: var(--white);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 5px;
    padding-right: 6px;
}

.sampleProvisionsDiv .sampleProvisionsLower .slideRightBtn {
    position: absolute;
    right: -1%;
    top: 50%;
    height: 45px;
    width: 45px;
    background: var(--primaryBlue);
    color: var(--white);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 5px;
    padding-left: 6px;
}


.sampleProvisionsDiv .carousel-container {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.sampleProvisionsDiv .carousel-track {
    display: grid;
    gap: 3px;
    transition: transform 0.3s ease-in-out;
    width: 100%;
    padding: 15px 5px;
}






/* **************** PRODUCT CARD **************** */

.product {
    padding: 10px;
    width: 95%;
    border-radius: 8px;
    background-color: var(--white);
    box-shadow: 0.1px 0.1px 10px 1px var(--gray);
    transform: translateY(0%);
    transition: all 0.3s ease-out;
    position: relative;
}

.product:hover {
    transform: translateY(-1.5%);
}

.product .productImgDiv {
    width: 100%;
    height: 235px;
    background: var(--lightGray);
    border-radius: 8px;
}

.product .productImgDiv img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
}

.product .productTitle {
    font-size: 1rem;
    color: var(--black);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: inherit;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 3em;
    margin-top: 10px;
    font-weight: 500;
}


.product .productLower {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
}

.product .productLower .productLowerTop {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    gap: 5px;
}

.product .productLower .productLowerBottom {
    display: flex;
    gap: 15px;
    justify-content: right;
    align-items: baseline;
}

.product .productLower .productLowerTop p {
    color: var(--lightBlack);
    font-weight: 600;
}

.product .productLower .productLowerTop span {
    color: var(--primaryCopper);
    font-weight: 500;
}

.product .productLower .productPrice {
    color: var(--secondaryBlue);
    font-size: 1.8rem;
    margin-right: 5px;
}

.product .productLower .productDiscount {
    color: var(--darkGray);
    text-decoration: line-through;
    font-size: 1.2rem;
}

.service .hoverActions,
.product .hoverActions {
    position: absolute;
    right: 10%;
    top: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    color: var(--primaryCopperDark) !important;
}

.service .hoverActions .iconDiv,
.service .hoverActions .loading,
.product .hoverActions .iconDiv,
.product .hoverActions .loading {
    background: var(--orangeBgLight);
    width: 35px;
    height: 35px;
    padding: 8px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s;
}

.service .hoverActions .iconDiv:hover,
.product .hoverActions .iconDiv:hover {
    background: var(--orangeBg);
}






/* **************** PRODUCT DETAILS **************** */

.productDetailsDiv {
    margin: 20px 0px 50px 0px;
}

.productDetailsDiv .upper {
    display: flex;
    justify-content: space-between;
}

.productDetailsDiv .upper .upperLeft {
    width: 40%;
}

.productDetailsDiv .upper .upperLeft .productThumbnail {
    width: 100%;
    max-height: 420px;
    height: fit-content;
    object-fit: cover;
    border-radius: 8px;
}

.productDetailsDiv .upper .upperLeft .productGalleryDiv {
    display: flex;
    width: 100%;
    gap: 10px;
    margin-top: 10px;
    justify-content: left;
}

.productDetailsDiv .upper .upperLeft .productGalleryDiv img {
    width: 15%;
    border: 3px solid transparent;
    cursor: pointer;
    border-radius: 8px;
    transition: 0.2s;
    object-fit: cover;
}

.productDetailsDiv .upper .upperRight {
    width: 52%;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.productDetailsDiv .upper .upperRight .ratingsDiv {
    display: flex;
    gap: 8px;
    align-items: center;
}

.productDetailsDiv .upper .upperRight .productTitle {
    font-weight: 600;
    font-size: 1.2rem;
}

.productDetailsDiv .upper .upperRight .sellerInfo {
    display: flex;
    align-items: center;
    gap: 8px;
}

.productDetailsDiv .upper .upperRight .sellerInfo img {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 50%;
}

.productDetailsDiv .upper .upperRight .sellerInfo a {
    color: var(--black);
}

.productDetailsDiv .upper .upperRight .productInfo {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.productDetailsDiv .upper .upperRight .productInfo p {
    width: 49%;
    color: var(--primaryCopper);
}

.productDetailsDiv .upper .upperRight .productInfo strong {
    color: var(--black);
}

.productDetailsDiv .upper .upperRight .productPrice {
    display: flex;
    gap: 20px;
    align-items: baseline;
}

.productDetailsDiv .upper .upperRight .productPrice .salesPrice {
    font-size: 1.8rem;
    color: var(--primaryCopper);
}

.productDetailsDiv .upper .upperRight .productPrice .price {
    color: var(--darkGray);
    text-decoration: line-through;
    font-size: 1.2rem;
}

.productDetailsDiv .upper .upperRight .productPrice .off {
    color: var(--white);
    background-color: var(--primaryBlue);
    padding: 8px 15px;
}

.productDetailsDiv .upper .upperRight .productActions {
    display: flex;
    gap: 15px;
    justify-content: space-between;
}

.productDetailsDiv .upper .upperRight .productActions .primaryBtn,
.productDetailsDiv .upper .upperRight .productActions .primaryBtn2 {
    width: 100%;
    text-align: center;
}

.productDetailsDiv .upper .upperRight .productActions .addToCartBtn {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    width: 150%;
}

.productDetailsDiv .upper .upperRight .addToWishlistBtn {
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--primaryCopper);
    cursor: pointer;
}

.productDetailsDiv .upper .upperRight .buyNowBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.productDetailsDiv .lower {
    margin-top: 100px;
    border: 1px solid var(--gray);
    border-radius: 8px;
}

.productDetailsDiv .lower .upper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0px;
    justify-content: center;
}

.productDetailsDiv .lower .upper p {
    color: var(--primaryCopper);
    padding: 15px;
    border-bottom: 5px solid transparent;
    cursor: pointer;
}

.productDetailsDiv .lower .upper .active {
    background: var(--primaryBlue);
    color: var(--white);
    border-color: var(--primaryCopper);
}

.productDetailsDiv .lower .innerLower,
.productDetailsDiv .lower .productReviews {
    padding: 35px;
}





/* **************** SERVICE CARD **************** */

.service {
    padding: 10px;
    width: 95%;
    border-radius: 8px;
    background-color: var(--white);
    box-shadow: 0.1px 0.1px 10px 1px var(--gray);
    transform: translateY(0%);
    transition: all 0.3s ease-out;
}

.service:hover {
    transform: translateY(-1.5%);
}

.service .serviceImgDiv {
    width: 100%;
    height: 180px;
}

.service .serviceImgDiv img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
}

.service .serviceTitle {
    font-size: 1rem;
    color: var(--black);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: inherit;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 3em;
    margin-top: 10px;
    font-weight: 500;
}


.service .serviceLower {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
}

.service .serviceLower .serviceLowerTop {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    gap: 5px;
}

.service .serviceLower .serviceLowerBottom {
    display: flex;
    gap: 15px;
    justify-content: right;
    align-items: baseline;
    color: var(--darkGray);
}

.service .serviceLower .serviceLowerTop p {
    color: var(--lightBlack);
    font-weight: 600;
}

.service .serviceLower .serviceLowerTop span {
    color: var(--primaryCopper);
    font-weight: 500;
}

.service .serviceLower .servicePrice {
    color: var(--secondaryBlue);
    font-size: 1.8rem;
    margin-right: 5px;
}

.service .serviceLower .serviceDiscount {
    color: var(--darkGray);
    text-decoration: line-through;
    font-size: 1.2rem;
}






/* **************** SETTINGS **************** */

.settingsDiv {
    padding: 20px 0px 50px 0px;
}

.settingsDiv .settingsContent {
    padding: 50px;
    box-shadow: 1px 1px 10px 1px var(--gray);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.settingsDiv .becomeSellerDiv .section {
    width: 100%;
}

.settingsDiv .becomeSellerDiv,
.settingsDiv .becomeSellerDiv .becomeSellerContent {
    padding: 0;
    box-shadow: none;
}

.settingsDiv .sellerSettings .primaryHeading {
    margin-bottom: 15px;
}

.settingsDiv .changePassDiv {
    width: 100%;
    margin: 10px 0px;
    user-select: none;
}

.settingsDiv .changePassDiv .upper {
    display: flex;
    justify-content: space-between;
    border-top: 2px solid var(--gray);
    border-bottom: 2px solid var(--gray);
    padding: 10px;
    cursor: pointer;
    background: transparent;
    transition: 0.1s;
}

.settingsDiv .changePassDiv .upper:hover {
    background: var(--extraLightGray);
}

.settingsDiv .changePassDiv .upper .icon {
    width: 25px;
    height: 25px;
}

.settingsDiv .changePassDiv .lower {
    margin-top: 20px;
}

.settingsDiv .changePassDiv .lower .secondaryBtn {
    margin-top: 10px;
}





/* **************** CATEGORIES PAGE **************** */


.categoriesDiv .categoriesContent {
    background: var(--white);
}

.categoriesDiv .serviceCatsDiv {
    margin-top: 50px;
}

.categoriesDiv .categories {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 0px 40px;
    margin-bottom: 50px;
}

.categoriesDiv .categoriesBanner {
    width: 100%;
}

.categoriesDiv .categories .category {
    cursor: pointer;
    border-bottom: 1px solid var(--gray);
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
    color: var(--black);
}

.categoriesDiv .categories .category:hover {
    background: var(--lightGray);
}

.categoriesDiv .categories .category .count {
    color: var(--darkGray);
    font-size: 0.9rem;
}

.categoriesDiv .categories .category i {
    color: var(--darkGray);
}



/* **************** PRODUCTS & SERVICES PAGES **************** */

.servicesDiv,
.productsDiv {
    margin: 20px 0px 50px 0px;
}

.servicesDiv .servicesContent,
.productsDiv .productsContent {
    display: flex;
    justify-content: space-between;
    gap: 60px;
}

.servicesDiv .filters,
.productsDiv .filters {
    display: flex;
    flex-direction: column;
    gap: 20px;
    min-width: 18%;
}

.servicesDiv .filters .filter,
.productsDiv .filters .filter {
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.servicesDiv .filters .filter .row,
.productsDiv .filters .filter .row {
    color: var(--lightBlack);
    font-size: 0.9rem;
    cursor: pointer;
    font-weight: 500;
    transition: 0.1s;
    display: flex;
    align-items: center;
}

.servicesDiv .filters .filter .row:hover,
.productsDiv .filters .filter .row:hover {
    color: var(--black);
}

.servicesDiv .filters .filter .row input,
.productsDiv .filters .filter .row input {
    margin-right: 5px;
}

.servicesDiv .filters .filter .active,
.productsDiv .filters .filter .active {
    color: var(--primaryCopper) !important;
}

.servicesDiv .left .categoryFilter .seeMore,
.productsDiv .left .categoryFilter .seeMore {
    cursor: pointer;
    color: var(--primaryCopper);
}

.servicesDiv .left .ratingFilter .rating,
.productsDiv .left .ratingFilter .rating {
    display: flex;
    gap: 2px;
}

.servicesDiv .left .ratingFilter .rating .up,
.productsDiv .left .ratingFilter .rating .up {
    margin-left: 10px;
}

.servicesDiv .right,
.productsDiv .right {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    min-width: 75%;
}

.servicesDiv .right .activeFilters,
.productsDiv .right .activeFilters {
    display: flex;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;
}

.servicesDiv .right .activeFilters .filterTag,
.productsDiv .right .activeFilters .filterTag {
    display: flex;
    align-items: center;
    background-color: var(--lightGray);
    padding: 5px 15px;
    border-radius: 5px;
}

.servicesDiv .right .activeFilters .filterTag button,
.productsDiv .right .activeFilters .filterTag button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    line-height: 1;
    margin-left: 10px;
}

.servicesDiv .right .resultsFound span,
.productsDiv .right .resultsFound span {
    color: var(--primaryCopper);
    font-weight: 500;
}

.servicesDiv .right .services,
.productsDiv .right .products {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: 20px 0px;
    margin-bottom: 50px;
    gap: 25px;
}

.servicesDiv .right .services .service,
.productsDiv .right .products .product {
    width: 100%;
}




/* **************** WISHLIST PAGE **************** */

.tableDiv .rows .row .field .cartCheck {
    color: var(--success);
}

.tableDiv .rows .row .field .cartCheck:hover {
    cursor: not-allowed;
    color: var(--success);
}

.tableDiv .rows .row .field .arrowRight {
    background: var(--lightGray);
    border-radius: 50%;
    padding: 3px;
    transition: 0.1s;
}

.tableDiv .rows .row .field .arrowRight:hover {
    background: var(--gray);
}


.tableDiv .rows .row .titleField .imgDiv {
    width: 15%;
    height: 100%;
}

.tableDiv .rows .row .titleField .imgDiv img {
    width: 100%;
}

.tableDiv .rows .row .categoryField {
    color: var(--primaryCopper);
    cursor: pointer;
    gap: 5px;
}

.tableDiv .rows .row .sellerField {
    cursor: pointer;
    font-weight: 500;
    gap: 5px;
}



/* **************** CART PAGE **************** */

.cartDiv {
    padding: 20px 0px 50px 0px;
}

.cartDiv .cartContent .section {
    display: flex;
    justify-content: space-between;
}

.cartDiv .left {
    border-radius: 8px;
    width: 70%;
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: fit-content;
    box-shadow: 1px 1px 10px 1px var(--gray);
}

.cartDiv .left .secondaryHeading .totalItems {
    font-size: 1rem;
    color: var(--darkGray);
    margin-left: 5px;
}

.cartDiv .left .cartProducts {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
    margin-top: 10px;
}

.cartDiv .left .cartProducts .cartElem {
    display: flex;
    gap: 30px;
    margin-bottom: 40px;
}

.cartDiv .left .cartProducts .cartElem .imgDiv {
    width: 120px;
}

.cartDiv .left .cartProducts .cartElem .imgDiv img {
    width: 100%;
}

.cartDiv .left .cartProducts .cartElem .cartElemContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
}

.cartDiv .left .cartProducts .cartElem .cartElemContent .cartElemTop {
    display: flex;
    justify-content: space-between;
}

.cartDiv .left .cartProducts .cartElem .cartElemContent .cartElemTop .title {
    font-size: 1.1rem;
    font-weight: 600;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: inherit;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 1.5em;
    width: 88%;
}

.cartDiv .left .cartProducts .cartElem .cartElemContent .cartElemTop .icon {
    width: 25px;
    height: 25px;
    cursor: pointer;
    color: var(--darkGray);
    transition: 0.1s;
}

.cartDiv .left .cartProducts .cartElem .cartElemContent .cartElemTop .icon:hover {
    color: var(--black);
}

.cartDiv .left .cartProducts .cartElem .cartElemContent .shippingFee span {
    color: var(--primaryCopper);
}

.cartDiv .left .cartProducts .cartElem .cartElemContent .cartElemBottom {
    display: flex;
    justify-content: space-between;
}

.cartDiv .left .cartProducts .cartElem .cartElemContent .cartElemBottom .cartCountBtn {
    width: 20%;
    height: 30px;
}

.cartDiv .left .cartProducts .cartElem .cartElemContent .cartElemBottom .cartCountBtn p {
    font-size: 14px;
}

.cartDiv .left .cartProducts .cartElem .cartElemContent .cartElemBottom .price {
    font-size: 2rem;
    font-weight: 700;
    color: var(--primaryCopper);
    display: flex;
    align-items: baseline;
    gap: 15px;
    justify-content: left;
}

.cartDiv .left .cartProducts .cartElem .cartElemContent .cartElemBottom .price span {
    font-size: 1.2rem;
    color: var(--darkGray);
    font-weight: 300;
    text-decoration: line-through;
}

.cartDiv .summary {
    border-radius: 8px;
    width: 25%;
    height: fit-content;
    padding: 20px 30px;
    box-shadow: 1px 1px 10px 1px var(--gray);
}

.cartDiv .summary .summaryContent {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.cartDiv .summary .summaryContent .row {
    display: flex;
    justify-content: space-between;
}

.cartDiv .summary .summaryContent .row .subTotal {
    color: var(--primaryCopper);
}





/* **************** POST REQUEST PAGE **************** */

.postRequestDiv .requestRow .offersField {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px !important;
    color: var(--primaryCopper);
    font-weight: 600;
    cursor: pointer;
}

.postRequestDiv .offersModalDiv .offersModelContent {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.postRequestDiv .rows .row .field .icon {
    width: 18px;
    height: 18px;
}


.offersModalDiv .popupContent {
    min-width: 80%;
}

.offersModalDiv .offersRows .tableContent .header .title,
.offersModalDiv .offersRows .tableContent .rows .row .titleField {
    width: 400%;
    font-size: 0.9rem;
}

.offersModalDiv .offersRows .tableContent .header .seller {
    width: 150%;
}

.offersModalDiv .offersRows .tableContent .rows .row .sellerField {
    width: 150%;
    cursor: pointer;
}

.offersModalDiv .offersRows .tableContent .rows .row .field {
    align-items: start;
}

.offersModalDiv .offersRows .tableContent .rows .row .field .icon {
    margin-top: 3px;
    width: 22px;
    height: 22px;
}

.postNewRequestDiv .postNewRequestContent {
    min-width: 67%;
}

.postNewRequestDiv .postNewRequestContent .form {
    gap: 8px;
}




/* **************** CHECKOUT PAGE **************** */

.checkoutDiv {
    padding: 20px 0px 50px 0px;
}

.checkoutDiv .checkoutContent {
    display: flex;
    justify-content: space-between;
}

.checkoutDiv .billingInfo {
    padding: 20px 30px;
    width: 60%;
    box-shadow: 1px 1px 10px 1px var(--gray);
    height: fit-content;
    border-radius: 8px;
}
.checkoutDiv .billingInfo .inputField:disabled{
    background: var(--lightGray);
    cursor: not-allowed;
    border: 1px solid var(--gray);
}
.checkoutDiv .billingInfo .billingInfoUpper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.checkoutDiv .billingInfo .billingInfoUpper .editBtn{
    cursor: pointer;
    border: none;
    background: none;
    font-size: 1.1rem;
    padding: 10px;
    border-radius: 4px;
    transition: background-color 0.2s ease;
    color: var(--lightBlackPrimary);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.1s;
    background-color: var(--orangeBgLight);
}
.checkoutDiv .billingInfo .billingInfoUpper .editBtn:hover{
    background-color: var(--orangeBg);
    color: var(--primaryCopper);
}

.checkoutDiv .orderSummary {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px 30px;
    width: 37%;
    height: fit-content;
    box-shadow: 1px 1px 10px 1px var(--gray);
    border-radius: 8px;
}

.checkoutDiv .orderSummary .item {
    display: flex;
    gap: 15px;
    justify-content: space-between;
    align-items: center;
}

.checkoutDiv .orderSummary .item .imgDiv {
    width: 90px;
}

.checkoutDiv .orderSummary .item .imgDiv img {
    width: 100%;
    border-radius: 8px;
}

.checkoutDiv .orderSummary .item .itemContent {
    width: 80%;
}

.checkoutDiv .orderSummary .item .itemContent .price {
    color: var(--primaryCopper);
    font-weight: 500;
    margin-top: 5px;
}

.checkoutDiv .orderSummary .promoCodeBox {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
}

.checkoutDiv .orderSummary .row {
    display: flex;
    justify-content: space-between;
}

.checkoutDiv .orderSummary .row .subTotal {
    color: var(--primaryCopper);
}

.paymentMethodsDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 15px 0px;
}

.paymentMethodsDiv .paymentOptions {
    display: flex;
    justify-content: space-between;
    gap: 15px;
}

.paymentMethodsDiv .paymentOptions .paymentOption {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border: 1px solid var(--gray);
    padding: 40px;
    cursor: pointer;
    width: 100%;
    transition: 0.2s;
    border-radius: 8px;
}

.paymentMethodsDiv .paymentOptions .paymentOption.selected {
    background: var(--primaryBlue);
    color: var(--white);
}

.paymentMethodsDiv .paymentOptions .paymentOption:not(.selected):hover {
    background: var(--lightGray);
}

.paymentMethodsDiv .paymentOptions .paymentOption .icon {
    width: 40px;
    height: 40px;
    color: var(--secondaryBlue);
}

.paymentMethodsDiv .paymentOptions .paymentOption.selected .icon {
    color: var(--white);
}







/* *************** SERVICE DETAILS PAGE **************** */


.serviceDetailsDiv {
    margin: 20px 0px 80px 0px;
}

.serviceDetailsDiv .serviceDetailsContent {
    display: flex;
    flex-direction: column;
}

.serviceDetailsDiv .serviceInfo {
    display: flex;
    justify-content: space-between;
}

.serviceDetailsDiv .serviceInfo .serviceImages {
    width: 40%;
}

.serviceDetailsDiv .serviceInfo .serviceImages .serviceThumbnail {
    width: 100%;
    border-radius: 8px;
    max-height: 380px;
    height: fit-content;
    object-fit: cover;
}

.serviceDetailsDiv .serviceInfo .serviceImages .serviceGalleryDiv {
    display: flex;
    width: 100%;
    gap: 10px;
    margin-top: 10px;
    justify-content: left;
}

.serviceDetailsDiv .serviceInfo .serviceImages .serviceGalleryDiv img {
    width: 18%;
    border: 3px solid transparent;
    cursor: pointer;
    border-radius: 8px;
    transition: 0.2s;
    object-fit: cover;
}

.serviceDetailsDiv .serviceInfo .info {
    width: 52%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.serviceDetailsDiv .serviceInfo .info .ratingsDiv {
    display: flex;
    gap: 8px;
    align-items: center;
}

.serviceDetailsDiv .serviceInfo .info .title {
    font-weight: 600;
    font-size: 1.2rem;
}

.serviceDetailsDiv .serviceInfo .info .sellerInfo {
    display: flex;
    align-items: center;
    gap: 8px;
}

.serviceDetailsDiv .serviceInfo .info .sellerInfo img {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 50%;
}

.serviceDetailsDiv .serviceInfo .info .sellerInfo a {
    color: var(--black);
}

.serviceDetailsDiv .serviceInfo .info .category {
    color: var(--primaryCopper);
    cursor: pointer;
    font-weight: 500;
}

.serviceDetailsDiv .serviceInfo .info .servicePrice {
    display: flex;
    gap: 10px;
    align-items: baseline;
}

.serviceDetailsDiv .serviceInfo .info .servicePrice .salesPrice {
    font-size: 1.8rem;
    color: var(--primaryCopper);
}

.serviceDetailsDiv .serviceInfo .info .servicePrice .price {
    color: var(--darkGray);
    text-decoration: line-through;
    font-size: 1.2rem;
    margin-left: 10px;
}

.serviceDetailsDiv .serviceInfo .info .servicePrice .off {
    color: var(--white);
    background-color: var(--primaryBlue);
    padding: 8px 15px;
}

.serviceDetailsDiv .serviceInfo .info .serviceActions {
    display: flex;
    gap: 15px;
    justify-content: space-between;
}

.serviceDetailsDiv .serviceInfo .info .serviceActions .moreActions {
    display: flex;
    gap: 15px;
}

.serviceDetailsDiv .serviceInfo .info .serviceActions .addToWishlistBtn {
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--primaryCopper);
    cursor: pointer;
}

.serviceDetailsDiv .packages {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding: 80px 0px 50px 0px;
}

.serviceDetailsDiv .packages .packageBox {
    display: flex;
    flex-direction: column;
    gap: 15px;
    border: 1px solid var(--gray);
    border-radius: 8px;
    width: 100%;
}

.serviceDetailsDiv .packages .packageBox .upper {
    text-align: center;
    padding-top: 15px;
    font-weight: 500;
    font-size: 1.2rem;
}

.serviceDetailsDiv .packages .packageBox .BASIC {
    color: var(--danger);
}

.serviceDetailsDiv .packages .packageBox .STANDARD {
    color: var(--secondaryBlue);
}

.serviceDetailsDiv .packages .packageBox .ULTIMATE {
    color: var(--warning);
}

.serviceDetailsDiv .packages .packageBox .lower {
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 10px 30px;
    padding-bottom: 25px;
}

.serviceDetailsDiv .packages .packageBox .lower .title {
    font-size: 1.2rem;
    font-weight: 600;
}

.serviceDetailsDiv .packages .packageBox .lower .info {
    display: flex;
    justify-content: space-between;
    color: var(--lightBlackPrimary);
    font-weight: 500;
    align-items: center;
}

.serviceDetailsDiv .packages .packageBox .lower .info .priceDetails {
    display: flex;
    gap: 20px;
    align-items: center;
}

.serviceDetailsDiv .packages .packageBox .lower .info .priceDetails .discount {
    color: var(--darkGray);
    text-decoration: line-through;
    font-size: 1.2rem;
}

.serviceDetailsDiv .packages .packageBox .lower .info .priceDetails .price {
    color: var(--white);
    background: var(--lightBlackPrimary);
    padding: 6px 20px;
    border-radius: 8px;
    font-size: 1.2rem;
}

.serviceDetailsDiv .aboutService,
.serviceDetailsDiv .aboutSeller {
    padding: 20px 30px;
    margin-bottom: 50px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    box-shadow: 1px 1px 10px 1px var(--gray);
}

.serviceDetailsDiv .aboutSeller .upper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.serviceDetailsDiv .aboutSeller .upper .sellerProfile {
    display: flex;
    gap: 25px;
    font-size: 0.9rem;
    align-items: center;
}

.serviceDetailsDiv .aboutSeller .upper .sellerProfile .imgDiv {
    width: 70px;
    height: 70px;
}

.serviceDetailsDiv .aboutSeller .upper .sellerProfile .imgDiv img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.serviceDetailsDiv .aboutSeller .upper .sellerProfile .profileInfo {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.serviceDetailsDiv .aboutSeller .upper .sellerProfile .profileInfo .username {
    font-size: 0.85rem;
    cursor: pointer;
    font-weight: 500;
    color: var(--lightBlackPrimary);
}

.serviceDetailsDiv .aboutSeller .upper .sellerProfile .profileInfo .username:hover {
    color: var(--black);
}

.serviceDetailsDiv .aboutSeller .upper .sellerProfile .profileInfo .ratingsDiv {
    display: flex;
    gap: 8px;
    align-items: center;
}

.serviceDetailsDiv .aboutSeller .upper .sellerInfo {
    display: flex;
    gap: 80px;
    margin-right: 20px;
}

.serviceDetailsDiv .aboutSeller .upper .sellerInfo .col p {
    font-size: 0.85rem;
}

.serviceDetailsDiv .sampleProvisionsDiv {
    margin: 0;
    margin-bottom: 50px;
}

.serviceDetailsDiv .serviceReviews {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 8px;
    padding: 20px 30px;
    box-shadow: 1px 1px 10px 1px var(--gray);
}

.serviceDetailsDiv .serviceReviews .reviewDiv {
    margin-top: 20px;
}




/* *************** REQUIREMENTS PAGE **************** */

.requirementsDiv {
    margin: 50px 0px;
}

.requirementsDiv .requirementsContent {
    padding: 20px 40px 30px 40px;
    box-shadow: 1px 1px 10px 1px var(--gray);
}





/* *************** CHAT PAGE **************** */


.chatDiv .section {
    width: 100%;
}

.chatDiv .chatContent {
    border-radius: 8px;
    border-top: 1px solid var(--gray);
    background: var(--white);
    display: flex;
    height: 110vh;
}

.adminDiv .chatDiv .chatContent {
    border: none;
}

.chatDiv .icon {
    cursor: pointer;
    color: var(--darkGray);
    transition: 0.2s;
}

.chatDiv .icon:hover {
    color: var(--black);
}

.chatDiv a {
    color: var(--primaryCopper);
}

.chatDiv .content {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.chatDiv .chatsList,
.chatDiv .chatRoom,
.chatDiv .otherInfo {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.chatDiv .chatsList {
    width: 25%;
    border-right: 2px solid var(--lightGray);
}

.chatDiv .chatRoom {
    position: relative;
    width: 50%;
    border-right: 2px solid var(--lightGray);
}

.chatDiv .otherInfo {
    width: 25%;
}

.chatDiv .header {
    padding: 20px 30px;
    border-bottom: 2px solid var(--lightGray);
    display: flex;
    justify-content: left;
    align-items: center;
    font-weight: 600;
    font-size: 1.2rem;
}

.chatDiv .header .highlight {
    border-radius: 50%;
    background: var(--orangeBg);
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primaryCopper);
    font-size: 0.75rem;
}

.chatDiv .chatsList .header {
    justify-content: center;
    gap: 15px;
}

.chatDiv .chatRoom .header {
    gap: 15px;
}

.chatDiv .chatRoom .header img {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 50%;
}

.chatDiv .otherInfo .header {
    justify-content: space-between;
}

.chatDiv .chatsList .chatBoxes {
    padding: 20px 30px;
    height: 100%;
    overflow-y: auto;
}

.chatDiv .chatsList .chatBoxes .chatBox {
    display: flex;
    gap: 15px;
    cursor: pointer;
    padding: 10px 15px;
    border-radius: 8px;
}

.chatDiv .chatsList .chatBoxes .active {
    background: var(--lightGray);
}

.chatDiv .chatsList .chatBoxes .chatBox .imgDiv {
    width: 60px;
    height: 50px;
}

.chatDiv .chatsList .chatBoxes .chatBox .chatBoxContent {
    width: 100%;
}

.chatDiv .chatsList .chatBoxes .chatBox .imgDiv img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.chatDiv .chatsList .chatBoxes .chatBox .lastMessage {
    font-size: 0.7rem;
    color: var(--darkGray);
    margin-top: 5px;
    font-weight: 400;
}


.chatDiv .chatRoom .messages .offerMessage {
    /* border: 1px solid var(--gray); */
    background: var(--orangeBgHover);
    border-radius: 8px;
    margin-bottom: 10px;
    color: var(--black);
}

.chatDiv .chatRoom .messages .offerMessage .upper,
.chatDiv .chatRoom .messages .offerMessage .middle,
.chatDiv .chatRoom .messages .offerMessage .bottom,
.chatDiv .chatRoom .messages .offerMessage .buttonsDiv {
    padding: 10px 20px;
}

.chatDiv .chatRoom .messages .offerMessage .upper {
    border-bottom: 1px solid var(--gray);
    display: flex;
    align-items: center;
}

.chatDiv .chatRoom .messages .offerMessage .upper .price {
    font-size: 1.3rem;
    font-weight: 600;
    color: var(--primaryCopper);
    margin-left: 20px;
}

.chatDiv .chatRoom .messages .offerMessage .bottom {
    border-top: 1px solid var(--gray);
    display: flex;
    gap: 5px;
    flex-direction: column;
}

.chatDiv .chatRoom .messages .offerMessage .bottom .row {
    display: flex;
    align-items: center;
    gap: 10px;
}

.chatDiv .chatRoom .messages .offerMessage .buttonsDiv {
    margin: 5px 0px;
    display: flex;
    justify-content: right;
    border-top: 1px solid var(--gray);
}

.chatDiv .chatRoom .messages .offerMessage .buttonsDiv a {
    font-size: 0.75rem;
    padding: 6px 18px;
    color: var(--black);
    margin-left: 10px;
}

.chatDiv .chatRoom .messages .offerMessage .buttonsDiv a:hover {
    color: var(--white);
}

.chatDiv .chatRoom .messages .offerMessage .buttonsDiv a.disabled:hover {
    color: var(--black);
}

.chatDiv .otherInfo .participantDetails {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px 30px;
}

.chatDiv .otherInfo .participantDetails .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chatDiv .otherInfo .sampleProvisionsDiv {
    margin: 10px 0px;
}

.chatDiv .otherInfo .sampleProvisionsDiv .sampleProvisionsLower .carousel-track {
    padding-left: 12px;
    gap: 17px;
}

.chatDiv .otherInfo .sampleProvisionsDiv .sampleProvisionsLower .product {
    width: 99%;
}

.chatDiv .otherInfo .sampleProvisionsDiv .sampleProvisionsLower .service {
    width: 99%;
}

.chatDiv .popupDiv .showQuoteModelContent {
    width: 55%;
}





/* *************** CONTACT PAGE **************** */

.contactDiv .contactContent {
    border-radius: 8px;
    padding: 50px;
    margin: 20px 0px 50px 0px;
    box-shadow: 1px 1px 10px 1px var(--gray);
}




/* *************** PROFILE PAGE **************** */

.profileDiv {
    padding-bottom: 100px;
}

.profileDiv .bg {
    height: 18vh;
    width: 100%;
    background: var(--secondaryBlue);
    position: relative;
}

.profileDiv .bg img {
    position: absolute;
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
    bottom: -60%;
    left: 45%;
}

.profileDiv .profileContent {
    display: flex;
    flex-direction: column;
    gap: 50px;
    margin-top: 120px;
}

.profileDiv .profileContent .upper {
    display: flex;
    justify-content: space-between;
    gap: 50px;
}

.profileDiv .profileContent .upper .sellerInfo,
.profileDiv .profileContent .upper .aboutSeller {
    height: fit-content;
    padding: 20px 30px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-width: 30%;
    box-shadow: 1px 1px 10px 1px var(--gray);
}

.profileDiv .profileContent .upper .aboutSeller {
    flex: 1;
}

.profileDiv .profileContent .upper .sellerInfo .row {
    display: flex;
    justify-content: space-between;
}

.profileDiv .profileContent .upper .sellerInfo .buttonsDiv {
    margin-top: 10px;
}

.profileDiv .profileContent .upper .sellerInfo .buttonsDiv .btnsForAdmin {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.profileDiv .profileContent .upper .sellerInfo .buttonsDiv .btnsForAdmin div {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.profileDiv .profileContent .upper .sellerInfo .buttonsDiv .btnsForAdmin div a,
.profileDiv .profileContent .upper .sellerInfo .buttonsDiv .btnsForAdmin div button {
    width: 100% !important;
}

.profileDiv .sellerServices .services,
.profileDiv .sellerProducts .products {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 15px;
    margin: 20px 0px;
}

.profileDiv .sellerServices .services .service,
.profileDiv .sellerProducts .products .product {
    width: 100%;
}

.profileDiv .sellerServices .services.grid-1,
.profileDiv .sellerProducts .products.grid-1 {
    grid-template-columns: repeat(1, 1fr);
}

.profileDiv .sellerServices .services.grid-2,
.profileDiv .sellerProducts .products.grid-2 {
    grid-template-columns: repeat(2, 1fr);
}

.profileDiv .sellerServices .services.grid-3,
.profileDiv .sellerProducts .products.grid-3 {
    grid-template-columns: repeat(3, 1fr);
}

.profileDiv .sellerServices .services.grid-4,
.profileDiv .sellerProducts .products.grid-4 {
    grid-template-columns: repeat(4, 1fr);
}

.profileDiv .sellerServices .services.grid-5,
.profileDiv .sellerProducts .products.grid-5 {
    grid-template-columns: repeat(5, 1fr);
}


.profileDiv .sellerReviews {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 8px;
    padding: 20px 30px;
    box-shadow: 1px 1px 10px 1px var(--gray);
}

.profileDiv .sellerReviews .reviewDiv {
    margin-top: 20px;
}










/* *************** FAQ Component **************** */

.faqDiv {
    width: 100%;
    min-height: 75vh;
}

.faqSection {
    border-radius: 8px;
    padding: 30px 0px;
}

.faqDiv .faqSection .primaryHeading {
    text-align: center;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 2rem;
    color: var(--primaryBlue);
}

.faqDiv .faqSection .faqContent {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.faqDiv .faqSection .faqItem {
    border: 1px solid var(--lightGray);
    border-radius: 8px;
    overflow: hidden;
    transition: box-shadow 0.3s ease;
}

.faqDiv .faqSection .faqItem:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.faqDiv .faqSection .faqQuestion {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 15px 20px;
    background-color: var(--extraLightGray);
    transition: background-color 0.2s ease;
}

.faqDiv .faqSection .faqQuestion:hover {
    background-color: var(--lightGray);
}

.faqDiv .faqSection .faqToggleIcon {
    font-size: 18px;
    color: var(--primaryBlue);
    transition: transform 0.3s ease;
}

.faqDiv .faqSection .faqAnswer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease, padding 0.3s ease;
    padding: 0px 20px;
    background-color: var(--white);
}

.faqDiv .faqSection .faqAnswer.open {
    max-height: 1000px;
    padding-top: 15px;
    padding-bottom: 15px;
}






/* *************** JOIN OR SELLER BANNER **************** */

.joinSellerBanner {
    background-color: var(--orangeBgLight);
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 30px;
}

.joinSellerBanner .primaryHeading {
    margin-bottom: 10px;
}

.joinSellerBanner .joinSellerBannerActions {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 15px;
}

.joinSellerBanner .joinSellerBannerActions button {
    padding: 12px 20px;
    font-weight: 500;
}




/* *************** Subscribe Banner **************** */
.subscribeBannerDiv {
    margin: 30px 0;
    width: 100vw;
    position: relative;
    left: 50%;
    margin-left: -50vw;
}

.subscribeBannerDiv .subscribeContent {
    padding: 30px;
    background-color: var(--primaryBlue);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-direction: column;
}
.subscribeBannerDiv .subscribeContent h2{
    color: var(--white);
    text-align: center;
}
.subscribeBannerDiv .subscribeContent h2 span{
    color: var(--orangeOnDarkBg);
}

.subscribeBannerDiv .subscribeForm {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
}
.subscribeBannerDiv .subscribeForm .inputField {
    width: 100%;
}